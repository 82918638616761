import { DateInput, Edit, NumberInput, ReferenceField, SimpleForm, TextField } from "react-admin";

export default props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceField
        source="tutorId"
        reference="users"
        link="show"
      ><TextField source="name" /></ReferenceField>
      <NumberInput source="price" />
      <DateInput source="createdAt" />
      <DateInput source="updatedAt" />
    </SimpleForm>
  </Edit>
);
