import { BooleanField, Datagrid, DateField, List, TextField } from "react-admin";

export default props => (
  <List {...props} sort={{ field: "createdAt", order: "DESC" }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="fullName" />
      <TextField source="phone" />
      <BooleanField source="isZohoSync" />
      <TextField source="goal" />
      <TextField source="source" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
