import React from 'react';
import './VoucherImage.css';
import Button from '@mui/material/Button';
import html2canvas from 'html2canvas';

export default ({ image, voucher }) => (
  <>
    <Button
      onClick={() => {
        html2canvas(document.querySelector('#voucher-block')).then((canvas) => {
          // eslint-disable-next-line no-param-reassign
          canvas.style.display = 'none';
          const voucherImage = canvas.toDataURL('png');
          const a = document.createElement('a');
          a.setAttribute('download', `voucher-${voucher.id.slice(-4)}.png`);
          a.setAttribute('href', voucherImage);
          a.click();
        });
      }}
    >
      Save Voucher
    </Button>
    <div className="voucher-block" id="voucher-block">
      <img className="voucher" src={image} alt="voucher" />
      <div className="parent-name">{voucher.parentName}</div>
      <div className="student-name">{voucher.studentName}</div>
      <div className="phone">{voucher.phone}</div>
      <div className="registered-date">
        {voucher.createdAt.slice(0, 10).split('-').reverse().join('/')}
      </div>
    </div>
  </>
);
