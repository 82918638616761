import { BooleanField, useRecordContext } from "react-admin";
import React from "react";

const ExistBooleanField = (props) => {
  const record = useRecordContext(props);
  const { source, showFalse} = props;
  return <BooleanField {...props}
                       record={{
                         ...record,
                         /* eslint-disable-next-line no-nested-ternary */
                         [source]: record[source] ? true : (showFalse ? false : undefined)
                       }}
  />;
}

ExistBooleanField.defaultProps = {
  addLabel: true,
  showFalse: true
}

export default ExistBooleanField;



