import { List, Datagrid, TextField, ReferenceField, DateField, BooleanField } from 'react-admin';

export default (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="userId" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users" label="Email">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="callerNumber" />
      <TextField source="name" />
      <TextField source="zohoTag" />
      <TextField source="startWorkingDay" />
      <TextField source="endWorkingDay" />
      <TextField source="zohoAccount" />
      <BooleanField source="visible" />
    </Datagrid>
  </List>
);
