import { AutocompleteInput, Create, ReferenceInput, SimpleForm, TextInput } from 'react-admin';

export default () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="userId" reference="users" perPage={5000}>
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <TextInput source="resource" />
      <TextInput source="action" />
    </SimpleForm>
  </Create>
);
