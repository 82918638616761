import { v4 as uuidv4 } from "uuid";
import { Create, NumberInput, SimpleForm, TextInput } from "react-admin";

export default props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" defaultValue={uuidv4()} />
      <TextInput source="walletId" />
      <TextInput source="bookingId" />
      <NumberInput source="price" />
      <NumberInput source="revenue" defaultValue={0} />
      <TextInput source="status" defaultValue="success" />
      <TextInput source="type" defaultValue="deposit" />
    </SimpleForm>
  </Create>
);
