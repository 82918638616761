import {
  Edit,
  FieldTitle,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

const transformRecord = (record) => ({
  ...record,
  ledgerId: record.ledgerId || null,
  saleByStaffId: record.saleByStaffId || null,
});

export default (props) => (
  <Edit {...props} transform={transformRecord} redirect="show">
    <SimpleForm>
      <NumberInput source="price" />
      <NumberInput source="revenue" />
      <TextInput source="type" />
      <ReferenceInput source="ledgerId" reference="ledgers">
        <SelectInput optionText="name" allowEmpty resettable />
      </ReferenceInput>
      <TextInput source="note" />
      <ReferenceInput reference="staffs" source="saleByStaffId">
        <SelectInput optionText="name" allowEmpty resettable />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
