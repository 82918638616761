import * as React from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";

const CurrencyField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  return <>{new Intl.NumberFormat("en-US", { style: "currency", "currency": "VND" })
    .format(record[source])}</>;
};

CurrencyField.propTypes = {
  textAlign: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool
};

CurrencyField.defaultProps = {
  textAlign: "right",
  addLabel: true
};

export default CurrencyField;
