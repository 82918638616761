import React from "react";
import {
  BooleanField,
  ChipField,
  DateField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from "react-admin";

export default props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="userId" reference="users"><TextField source="id" /></ReferenceField>
      <ReferenceManyField
        source="userId"
        reference="userRoles" target="userId" sort={{ field: "roleId", order: "ASC" }}
        label="Roles"
      >
        <SingleFieldList>
          <ReferenceField reference="roles" source="roleId">
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="video" />
      <TextField source="bio" />
      <TextField source="languages" />
      <TextField source="specialties" />
      <TextField source="resume" />
      <TextField source="education" />
      <TextField source="experience" />
      <TextField source="accent" />
      <TextField source="targetStudent" />
      <TextField source="profession" />
      <TextField source="interests" />
      <BooleanField source="isActivated" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
