import { SelectInput, Create, ReferenceInput, SimpleForm, TextInput } from 'react-admin';

export default () => (
  <Create redirect="show">
    <SimpleForm>
      <ReferenceInput source="voucherTypeId" reference="voucherTypes">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="parentName" />
      <TextInput source="studentName" />
      <TextInput source="phone" />
    </SimpleForm>
  </Create>
);
