import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/react-hooks';
import { setContext } from '@apollo/client/link/context';
import Config from '../Config/index';

const httpLink = createHttpLink({
  uri: `${Config.apiUrl}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const auth = JSON.parse(localStorage.getItem('auth'));
  // return the headers to the context so httpLink can read them
  // if (auth)
  return {
    headers: {
      ...headers,
      authorization: auth ? `Bearer ${auth.accessToken}` : '',
    },
  };
  // return {
  //   headers,
  // };
});

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
