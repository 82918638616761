import React from 'react';
import { BooleanInput, Edit, SimpleForm, TextInput } from 'react-admin';

export default () => (
  <Edit>
    <SimpleForm>
      <TextInput source="video" fullWidth />
      <TextInput source="bio" fullWidth multiline />
      <TextInput source="languages" fullWidth />
      <TextInput source="specialties" fullWidth />
      <TextInput source="resume" fullWidth multiline />
      <TextInput source="education" fullWidth />
      <TextInput source="experience" fullWidth multiline />
      <TextInput source="accent" fullWidth />
      <TextInput source="targetStudent" fullWidth />
      <TextInput source="profession" fullWidth multiline />
      <TextInput source="interests" fullWidth multiline />
      <BooleanInput source="isActivated" />
      <BooleanInput source="isNative" />
    </SimpleForm>
  </Edit>
);
