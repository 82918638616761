import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  ImageField,
  List,
  ReferenceField,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';

const tutorListFilters = [
  <ReferenceInput source="userId" reference="users" perPage={5000} alwaysOn>
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <BooleanInput source="isActivated" alwaysOn />,
];

export default (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={tutorListFilters}>
    <Datagrid rowClick="show">
      <ReferenceField source="userId" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users" link="show">
        <ImageField source="avatar" sx={{ width: 50, height: 50 }} />
      </ReferenceField>
      <TextField source="languages" />
      <TextField source="education" />
      <TextField source="targetStudent" />
      <BooleanField source="isActivated" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
