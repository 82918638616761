import { DateField, ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin";
import CurrencyField from "../Custom/CurrencyField";

export default props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField
        source="walletId"
        reference="wallets"
      ><TextField source="id" /></ReferenceField>
      <ReferenceField
        source="bookingId"
        reference="bookings"
      ><TextField source="id" /></ReferenceField>
      <CurrencyField source="price" />
      <CurrencyField source="revenue" />
      <ReferenceField
        source="ledgerId"
        reference="ledgers"
      ><TextField source="name" /></ReferenceField>
      <TextField source="status" />
      <TextField source="type" />
      <TextField source="note" />
      <ReferenceField
        source="saleByStaffId"
        reference="staffs"
      ><TextField source="name" /></ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);
