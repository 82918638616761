import {
  BooleanField,
  DateField,
  EditButton,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useNotify,
} from 'react-admin';
import Button from '@mui/material/Button';
import React from 'react';
import axios from 'axios';
import Config from '../../Config/index';

const ConnectorShowActions = ({ basePath, data, resource }) => {
  const notify = useNotify();

  const sendToZoho = (id) => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    axios
      .post(`${Config.apiUrl}/connector/${id}/send-to-zoho`, null, {
        headers: { Authorization: `Bearer ${auth.accessToken}` },
      })
      .then((response) => {
        notify('Successfully sent to Zoho');
      })
      .catch((error) => {
        notify(error.toString(), 'error');
      });
  };
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      <Button color="primary" onClick={() => sendToZoho(data.id)}>
        Send to Zoho
      </Button>
    </TopToolbar>
  );
};

export default (props) => (
  <Show actions={<ConnectorShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="fullName" />
      <TextField source="phone" />
      <TextField source="goal" />
      <TextField source="source" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <BooleanField source="isZohoSync" />
    </SimpleShowLayout>
  </Show>
);
