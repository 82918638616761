import React from 'react';
import {
  BooleanInput,
  AutocompleteInput,
  Edit,
  ImageField,
  NumberInput,
  ReferenceInput,
  regex,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { emptyToNull } from '../../ReactAdmin/utils/transformInput';

const transformRecord = (record) => ({
  ...record,
  email: (record.email && record.email.trim()) || null,
  caredByStaffId: record.caredByStaffId || null,
  saleByStaffId: record.saleByStaffId || null,
  phoneAuth: record.phoneAuth || null,
});

export default (props) => (
  <Edit {...props} redirect="show" transform={transformRecord}>
    <SimpleForm redirect="show">
      <TextInput source="email" />
      <ImageField source="avatar" />
      <TextInput source="name" />
      <TextInput source="phone" validate={regex(/^84\d{9}$/, 'Phone number must begin with 84')} />
      <TextInput source="phoneAuth" emptyValue={null} />
      <BooleanInput source="isPhoneAuthActivated" />
      <BooleanInput source="isPhoneActivated" />
      <BooleanInput source="isActivated" />
      <BooleanInput source="canSendMessage" />
      <SelectInput
        allowEmpty
        source="referral"
        choices={[
          { name: 'Facebook Ads' },
          { name: 'Referral' },
          { name: 'Company Referral' },
          { name: 'Internal Referral' },
          { name: 'Cold call' },
          { name: 'Employee' },
          { name: 'Internal' },
          { name: 'Google Ads' },
          { name: 'Direct' },
        ]}
        optionValue="name"
      />
      <ReferenceInput
        reference="staffs"
        source="saleByStaffId"
        emptyValue={null}
        format={emptyToNull}
        parse={emptyToNull}
      >
        <AutocompleteInput
          optionText="name"
          allowEmpty
          resettable
          emptyValue={null}
          format={emptyToNull}
          parse={emptyToNull}
        />
      </ReferenceInput>
      <ReferenceInput
        reference="staffs"
        source="caredByStaffId"
        emptyValue={null}
        format={emptyToNull}
        parse={emptyToNull}
      >
        <AutocompleteInput
          optionText="name"
          allowEmpty
          resettable
          emptyValue={null}
          format={emptyToNull}
          parse={emptyToNull}
        />
      </ReferenceInput>
      <TextInput source="language" />
      <TextInput source="country" />
      <BooleanInput source="requestPassword" />
      <TextInput source="requireNote" />
      <BooleanInput source="isTester" />
      <NumberInput source="timezone" />
    </SimpleForm>
  </Edit>
);
