import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  DateInput,
  BooleanInput,
  TextField,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceInput source="userId" reference="users" perPage={10000}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="callerNumber" />
      <TextInput source="name" />
      <TextInput source="zohoTag" />
      <DateInput source="startWorkingDay" />
      <DateInput source="endWorkingDay" />
      <TextInput source="zohoAccount" />
      <BooleanInput source="visible" />
    </SimpleForm>
  </Edit>
);
