import * as React from 'react';
import { memo } from 'react';
import get from 'lodash/get';
import { Link, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

const LinkField = memo((props) => {
  const { className, emptyText, source, text, prefix, ...rest } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  if (value == null) {
    return (
      <Typography component="span" variant="body2" className={className} {...rest}>
        {emptyText}
      </Typography>
    );
  }

  return (
    <Link className={className} href={prefix + value} variant="body2" {...rest}>
      {text || value}
    </Link>
  );
});

LinkField.defaultProps = {
  addLabel: true,
  prefix: '',
};

LinkField.propTypes = {
  addLabel: PropTypes.bool,
  className: PropTypes.string,
  emptyText: PropTypes.string,
  source: PropTypes.string,
  text: PropTypes.string,
  prefix: PropTypes.string,
};
LinkField.displayName = 'LinkField';

export default LinkField;
