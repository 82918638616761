import React, { useEffect, useState } from 'react';
import { Admin, EditGuesser, ListGuesser, Resource, ShowGuesser } from 'react-admin';
import { useApolloClient } from '@apollo/react-hooks';
import pgDataProvider from 'ra-postgraphile';
import SimpleRestProvider from 'ra-data-simple-rest';
import StudentList from '../Student/StudentList';
import authProvider from '../../ReactAdmin/authProvider';
import UserShow from '../User/UserShow';
import WalletList from '../Wallet/WalletList';
import WalletShow from '../Wallet/WalletShow';
import BookingViewList from '../BookingView/BookingViewList';
import Config from '../../Config';
import TutorList from '../Tutor/TutorList';
import TutorShow from '../Tutor/TutorShow';
import FeeTutorList from '../FeeTutors/FeeTutorList';
import FeeTutorEdit from '../FeeTutors/FeeTutorEdit';
import TransactionViewList from '../TransactionView/TransactionViewList';
import TransactionShow from '../Transaction/TransactionShow';
import TransactionEdit from '../Transaction/TransactionEdit';
import TransactionCreate from '../Transaction/TransactionCreate';
import ConnectorList from '../Connector/ConnectorList';
import ConnectorShow from '../Connector/ConnectorShow';
import WalletEdit from '../Wallet/WalletEdit';
import BookingEdit from '../Booking/BookingEdit';
import BookingShow from '../Booking/BookingShow';
import UserEdit from '../User/UserEdit';
import ConnectorEdit from '../Connector/ConnectorEdit';
import LedgerList from '../Ledger/LedgerList';
import StaffList from '../Staff/StaffList';
import TalentList from '../Talent/TalentList';
import StaffEdit from '../Staff/StaffEdit';
import StaffCreate from '../Staff/StaffCreate';
import UserPermissionList from '../UserPermission/UserPermissionList';
import UserPermissionEdit from '../UserPermission/UserPermissionEdit';
import UserPermissionCreate from '../UserPermission/UserPermissionCreate';
import TutorEdit from '../Tutor/TutorEdit';
import VoucherCreate from '../Voucher/VoucherCreate';
import VoucherList from '../Voucher/VoucherList';
import VoucherShow from '../Voucher/VoucherShow';
import VoucherEdit from '../Voucher/VoucherEdit';

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const client = useApolloClient();
  const auth = localStorage.getItem('auth');

  useEffect(() => {
    if (!auth) {
      setDataProvider(new SimpleRestProvider(Config.apiUrl));
      return;
    }
    (async () => {
      try {
        const dp = await pgDataProvider(client);
        setDataProvider(() => dp);
      } catch (e) {
        // await authProvider.logout();
        // window.location.replace('/login');

        // Dummy data provider to activate auth provider
        setDataProvider(new SimpleRestProvider(Config.apiUrl));
      }
    })();
  }, [auth]);

  return (
    dataProvider && (
      <Admin dataProvider={dataProvider} authProvider={authProvider}>
        {(permissions) =>
          permissions ? (
            <>
              {(permissions['*'] || permissions.students) && (
                <Resource
                  name="students"
                  list={StudentList}
                  edit={EditGuesser}
                  show={ShowGuesser}
                />
              )}
              {(permissions['*'] || permissions.users) && (
                <Resource name="users" edit={UserEdit} show={UserShow} />
              )}
              <Resource name="nodes" />
              {(permissions['*'] || permissions.wallets) && (
                <Resource name="wallets" list={WalletList} edit={WalletEdit} show={WalletShow} />
              )}
              {(permissions['*'] || permissions.transactions) && (
                <Resource
                  name="transactions"
                  edit={TransactionEdit}
                  show={TransactionShow}
                  create={TransactionCreate}
                />
              )}
              {(permissions['*'] || permissions.bookings) && (
                <Resource name="bookings" edit={BookingEdit} show={BookingShow} />
              )}
              {(permissions['*'] || permissions.scheduleDetails) && (
                <Resource
                  name="scheduleDetails"
                  list={ListGuesser}
                  edit={EditGuesser}
                  show={ShowGuesser}
                />
              )}
              {(permissions['*'] || permissions.schedules) && (
                <Resource
                  name="schedules"
                  list={ListGuesser}
                  edit={EditGuesser}
                  show={ShowGuesser}
                />
              )}
              {(permissions['*'] || permissions.tutors) && (
                <Resource name="tutors" list={TutorList} edit={TutorEdit} show={TutorShow} />
              )}
              {(permissions['*'] || permissions.bookingViews) && (
                <Resource
                  name="bookingViews"
                  list={BookingViewList}
                  options={{ label: 'Bookings' }}
                />
              )}
              {(permissions['*'] || permissions.transactionViews) && (
                <Resource
                  name="transactionViews"
                  list={TransactionViewList}
                  options={{ label: 'Transactions' }}
                />
              )}
              {(permissions['*'] || permissions.feeTutors) && (
                <Resource
                  name="feeTutors"
                  list={FeeTutorList}
                  edit={FeeTutorEdit}
                  show={ShowGuesser}
                />
              )}
              {(permissions['*'] || permissions.userRoles) && <Resource name="userRoles" />}
              {(permissions['*'] || permissions.roles) && (
                <Resource name="roles" list={ListGuesser} />
              )}
              {(permissions['*'] || permissions.connectors) && (
                <Resource
                  name="connectors"
                  list={ConnectorList}
                  show={ConnectorShow}
                  edit={ConnectorEdit}
                  options={{ label: 'Leads' }}
                />
              )}
              {(permissions['*'] || permissions.ledgers) && (
                <Resource name="ledgers" list={LedgerList} />
              )}
              {(permissions['*'] || permissions.enum_Bookings_statuses) && (
                <Resource name="enum_Bookings_statuses" />
              )}
              {(permissions['*'] || permissions.staffs) && (
                <Resource name="staffs" list={StaffList} edit={StaffEdit} create={StaffCreate} />
              )}
              {(permissions['*'] || permissions.talents) && (
                <Resource name="talents" list={TalentList} />
              )}
              {(permissions['*'] || permissions.userPermissions) && (
                <Resource
                  name="userPermissions"
                  list={UserPermissionList}
                  edit={UserPermissionEdit}
                  create={UserPermissionCreate}
                />
              )}
              {(permissions['*'] || permissions.voucherTypes) && (
                <Resource name="voucherTypes" list={ListGuesser} />
              )}
              {(permissions['*'] || permissions.vouchers) && (
                <Resource
                  name="vouchers"
                  list={VoucherList}
                  create={VoucherCreate}
                  show={VoucherShow}
                  edit={VoucherEdit}
                />
              )}
            </>
          ) : (
            <></>
          )
        }
      </Admin>
    )
  );
};

export default App;
