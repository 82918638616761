import {
  BooleanField,
  Datagrid, DateField,
  EmailField,
  List,
  NumberField,
  TextField,
} from "react-admin";

export default props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="fullName" />
      <TextField source="phone" />
      <NumberField source="birthYear" />
      <TextField source="address" />
      <NumberField source="desiredSalary" />
      <BooleanField source="ownLaptop" />
      <TextField source="experience" />
      <BooleanField source="skip" />
      <BooleanField source="contacted" />
      <BooleanField source="acquired" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
