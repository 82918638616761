import { BooleanInput, DateField, Edit, SimpleForm, TextField, TextInput } from "react-admin";
import React from "react";

export default props => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextField source="id" />
      <TextInput source="fullName" />
      <TextInput source="phone" />
      <TextInput source="goal" />
      <TextInput source="source" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <BooleanInput source="isZohoSync" />
    </SimpleForm>
  </Edit>
);
