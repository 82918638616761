import React from 'react';
import {
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  TextField,
} from 'react-admin';

const UserPermissionFilter = [
  <ReferenceInput source="userId" reference="users" alwaysOn perPage={5000}>
    <AutocompleteInput optionText="email" allowEmpty resettable />
  </ReferenceInput>,
];

export default () => (
  <List filters={UserPermissionFilter}>
    <Datagrid rowClick="edit">
      <ReferenceField source="userId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="resource" />
      <TextField source="action" />
    </Datagrid>
  </List>
);
