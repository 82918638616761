import React, { useEffect, useState } from 'react';
import {
  BooleanField,
  ChipField,
  DateField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useRecordContext,
  useShowController,
  RecordContextProvider,
  useRedirect,
  useGetOne,
  Loading,
  useDataProvider,
  Error,
} from 'react-admin';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import VoucherImage from './VoucherImage/VoucherImage';

export default (props) => {
  const { id } = useParams();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [voucher, setVoucher] = useState();
  const [voucherType, setVoucherType] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getOne('vouchers', { id })
      .then(({ data }) => {
        setVoucher(data);
        dataProvider
          .getOne('voucherTypes', { id: data.voucherTypeId })
          .then(({ data: vt }) => {
            setVoucherType(vt);
            setLoading(false);
          })
          .catch((err) => {
            setError(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [id]);
  if (loading) {
    return <Loading />;
  }
  if (error) return <Error />;
  return (
    <RecordContextProvider value={voucher}>
      <Show>
        <Grid container mt={1}>
          <Grid item xs={12} sm={10}>
            {voucherType && voucher && <VoucherImage image={voucherType.image} voucher={voucher} />}
          </Grid>
          <Grid item xs={12} sm={2}>
            <SimpleShowLayout>
              <ReferenceField source="voucherTypeId" reference="voucherTypes">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="parentName" />
              <TextField source="studentName" />
              <TextField source="phone" />
              <DateField source="createdAt" showTime />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </Show>
    </RecordContextProvider>
  );
};
