import React from 'react';
import {
  Button,
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  ImageField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useNotify,
} from 'react-admin';
import ExistBooleanField from '../Custom/ExistBooleanField';
import CurrencyField from '../Custom/CurrencyField';
import LinkField from '../Custom/LinkField';
import Config from '../../Config/index';

const axios = require('axios');

const UserShowTitle = () => {
  const record = useRecordContext();
  return record ? <>{`User ${record.name}${record.phone ? ` (${record.phone})` : ''}`}</> : null;
};

function loginAs(userId) {
  const auth = JSON.parse(localStorage.getItem('auth'));
  axios
    .post(
      `${Config.apiUrl}/tutor/login-as-user-id`,
      { userId },
      { headers: { Authorization: `Bearer ${auth.accessToken}` } },
    )
    .then((response) => {
      window
        .open(
          `${process.env.REACT_APP_APP_URL}/login-as?token=${response.data.refreshToken}`,
          '_blank',
        )
        .focus();
    })
    .catch((error) => {
      console.log(error);
    });
}

const addTrial = (userId, notify) => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  axios
    .post(
      `${Config.apiUrl}/student/add-trial`,
      { userId },
      { headers: { Authorization: `Bearer ${auth.accessToken}` } },
    )
    .then((response) => {
      notify('Successfully add trial');
    })
    .catch((error) => {
      notify(error.message, 'error');
    });
};

const UserShowActions = () => {
  const record = useRecordContext();
  const notify = useNotify();
  return (
    <TopToolbar>
      <EditButton />

      <Button color="primary" onClick={() => loginAs(record.id)}>
        Login As
      </Button>
      <Button onClick={() => addTrial(record.id, notify)}>Add Trial</Button>
    </TopToolbar>
  );
};

export default (props) => (
  <Show title={<UserShowTitle />} actions={<UserShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Account">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="phone" />
        <BooleanField source="isActivated" />
        <BooleanField source="isPhoneActivated" />
        <EmailField source="email" />
        <BooleanField source="canSendMessage" />
        <TextField source="referral" />
        <ReferenceField source="saleByStaffId" reference="staffs">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="caredByStaffId" reference="staffs">
          <TextField source="name" />
        </ReferenceField>
        <ExistBooleanField source="google" label="Google" />
        <ExistBooleanField source="facebook" />
        <ExistBooleanField source="apple" />
        <ImageField source="avatar" sx={{ width: 100, height: 100 }} />
        <TextField source="country" />
        <TextField source="language" />
        <TextField source="birthday" />
        <BooleanField source="requestPassword" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <TextField source="deletedAt" />
        <TextField source="requireNote" />
        <TextField source="isTester" />
        <TextField source="level" />
        <NumberField source="timezone" />
      </Tab>
      <Tab label="Wallets">
        <ReferenceManyField reference="wallets" target="userId" addLabel={false}>
          <Datagrid rowClick="show">
            <CurrencyField source="amount" textAlign="right" />
            <BooleanField source="isBlocked" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Bookings">
        <ReferenceManyField
          reference="bookingViews"
          target="userId"
          addLabel={false}
          rowClick="show"
          pagination={<Pagination />}
          sort={{ field: 'startPeriodTimestamp', order: 'DESC' }}
        >
          <Datagrid rowClick={(id, basePath, record) => `/bookings/${record.id}/show`}>
            <DateField source="startPeriodTimestamp" showTime />
            <ReferenceField source="tutorId" reference="users" label="Tutor">
              <TextField source="name" />
            </ReferenceField>
            <ExistBooleanField source="isDeleted" showFalse={false} />
            <TextField source="studentRequest" />
            <TextField source="tutorReview" />
            <LinkField
              source="studentMeetingLink"
              prefix={Config.appUrl}
              text="Student lesson link"
            />
            <LinkField source="tutorMeetingLink" prefix={Config.appUrl} text="Tutor lesson link" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
