import {
  BooleanInput,
  DateInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

export default props => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextField source="id" />
      <ReferenceInput
        source="userId"
        reference="users"
      ><SelectInput optionText="name" /></ReferenceInput>
      <ReferenceInput
        source="scheduleDetailId"
        reference="scheduleDetails"
      ><SelectInput optionText="id" /></ReferenceInput>
      <DateInput source="createdAt" />
      <DateInput source="updatedAt" />
      <BooleanInput source="isDeleted" />
      <TextInput source="tutorMeetingLink" />
      <TextInput source="studentMeetingLink" />
      <TextInput source="studentRequest" />
      <SelectInput
        source="status"
        optionValue="name"
        choices={[{ name: "RESERVED" }, { name: "FINISHED" }, { name: "TUTOR_ABSENCE" }, { name: "STUDENT_ABSENCE" }, { name: "CANCELLED" }]}
        resettable
      />
      <TextInput source="tutorReview" />
      <TextInput source="scoreByTutor" />
      <TextInput source="recordUrl" />
    </SimpleForm>
  </Edit>
);
