import React from "react";
import { BooleanField, Datagrid, DateField, List, ReferenceField, TextField } from "react-admin";
import CurrencyField from "../Custom/CurrencyField";

export default props => (
  <List {...props} sort={{ field: "createdAt", order: "DESC" }}>
    <Datagrid rowClick="show">
      <ReferenceField
        source="userId"
        reference="users"
        link="show"
      ><TextField source="name" /></ReferenceField>
      <CurrencyField source="amount" textAlign="right" />
      <BooleanField source="isBlocked" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
