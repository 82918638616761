import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export default props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <ReferenceInput
        source="userId"
        reference="users"
      ><SelectInput optionText="name" /></ReferenceInput>
      <NumberInput source="amount" />
      <BooleanInput source="isBlocked" />
      <DateInput source="createdAt" />
      <DateInput source="updatedAt" />
      <DateInput source="bonus" />
    </SimpleForm>
  </Edit>
);
