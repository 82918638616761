import React from 'react';
import {
  Datagrid,
  DateField,
  EmailField,
  ImageField,
  List,
  NumberField,
  TextField,
  TextInput,
  NullableBooleanInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import ExistBooleanField from '../Custom/ExistBooleanField';
import CurrencyField from '../Custom/CurrencyField';

const userListFilters = [
  <TextInput source="name" alwaysOn />,
  <TextInput source="email" alwaysOn />,
  <TextInput source="phone" alwaysOn />,
  <ReferenceInput source="saleByStaffId" reference="staffs" alwaysOn>
    <SelectInput optionText="name" allowEmpty resettable />
  </ReferenceInput>,
  <ReferenceInput source="caredByStaffId" reference="staffs" alwaysOn>
    <SelectInput optionText="name" allowEmpty resettable />
  </ReferenceInput>,
  <NullableBooleanInput source="isActivated" alwaysOn />,
];

export default (props) => (
  <List
    {...props}
    filters={userListFilters}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={null}
  >
    <Datagrid rowClick={(id, basePath, record) => `/users/${record.id}/show`}>
      <ImageField source="avatar" sx={{ width: 40, height: 40 }} />
      <TextField source="name" />
      <TextField source="phone" />
      <ExistBooleanField source="isPhoneActivated" />
      <ExistBooleanField source="isActivated" />
      <EmailField source="email" />
      <NumberField source="bookingCount" options={{ style: 'percent' }} />
      <CurrencyField source="balance" />
      <CurrencyField source="totalRevenue" textAlign="right" />
      <TextField source="referral" />
      <ReferenceField source="saleByStaffId" reference="staffs">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="caredByStaffId" reference="staffs">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);
