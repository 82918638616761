import { Datagrid, DateField, List, ReferenceField, TextField } from "react-admin";
import CurrencyField from "../Custom/CurrencyField";

export default props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField
        source="tutorId"
        reference="users"
      ><TextField source="name" /></ReferenceField>
      <CurrencyField source="price" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
